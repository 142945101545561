@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.person {
  display: flex;
  flex-direction: column;
  padding: vw_d(155) vw_d(400) vw_d(50) vw_d(168);

  @include screen('mobile') {
    padding: vw(52) vw(25);
  }

  .fixed-content {
    display: flex;

    @include screen('mobile') {
      position: fixed;
      width: 100%;
      background-color: #000;
    }

    &.animation {
      background-position: -500px 0;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 20%,
        rgba(255, 255, 255, 0.2) 40%,
        rgba(255, 255, 255, 0.1) 100%
      );
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      animation: skeletonShine 0.5s linear 0s infinite normal forwards;

      .image-wrap,
      .info,
      .popup-btn,
      .no-memories {
        opacity: 0;
      }
    }

    .photo {
      position: fixed;

      @include screen('mobile') {
        position: static;
      }
    }

    .image-wrap {
      width: vw_d(400);
      height: vw_d(520);
      margin-top: vw_d(12);
      margin-bottom: vw_d(24);

      @include screen('mobile') {
        width: vw(112);
        height: vw(152);
        margin-top: vw(34);
        margin-bottom: vw(24);
      }

      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    .info {
      // position: absolute;
      // top: vw_d(-150);
      margin-right: vw_d(521);
      width: vw_d(830);
      // padding-top: vw_d(150);
      background-color: #000;

      @include screen('mobile') {
        position: absolute;
        top: 0;
        margin-top: vw(34);
        margin-right: 0;
        right: vw(132);
        width: vw(195);
      }

      h1 {
        font-family: $bold-font;
        font-size: vw_d(54);
        line-height: 1;
        margin: 0;

        @include screen('mobile') {
          font-size: vw(27);
        }
      }

      .additional {
        font-weight: 300;
        font-size: vw_d(35);
        line-height: 1;

        @include screen('mobile') {
          font-size: vw(21);
        }
      }
    }

    .popup-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: vw_d(400);
      height: vw_d(54);
      font-family: $medium-font;
      font-size: vw_d(26);
      white-space: nowrap;
      color: #fff;
      background-color: $red-color;
      transition: 0.3s;

      @include screen('mobile') {
        position: absolute;
        right: vw(132);
        bottom: vw(24);
        width: vw(185);
        height: vw(28);
        padding-left: vw(5);
        font-size: vw(15);
      }

      @include screen('desktop') {
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }

      span {
        padding-left: vw_d(10);

        @include screen('mobile') {
          padding-left: vw(6);
        }
      }

      svg {
        width: vw_d(25);
        margin-left: vw_d(-8);

        @include screen('mobile') {
          width: vw(11);
        }
      }
    }
  }

  .no-memories {
    font-family: $bold-font;
    font-size: vw_d(27);
    letter-spacing: -0.1px;
    margin: vw_d(15) vw_d(3);

    @include screen('mobile') {
      margin: 0 0 vw(16);
      font-size: vw(18);
    }
  }

  .story {
    font-size: vw_d(27);
    letter-spacing: -0.1px;
    margin: vw_d(15) vw_d(3);
    font-weight: 300;

    @include screen('mobile') {
      margin: 0 0 vw(16);
      font-size: vw(22);
      line-height: 1.1;
    }
  }

  .article-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: vw_d(207);
    height: vw_d(38);
    font-family: $medium-font;
    font-size: vw_d(18);
    white-space: nowrap;
    color: #fff;
    background-color: $red-color;
    transition: 0.3s;

    @include screen('mobile') {
      width: vw(132);
      height: vw(28);
      padding-left: vw(5);
      font-size: vw(15);
      margin-bottom: vw(36);
    }

    @include screen('desktop') {
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }

    span {
      padding-left: vw_d(16);

      @include screen('mobile') {
        padding-left: vw(6);
      }
    }

    svg {
      width: vw_d(20);
      margin-left: vw_d(-3);

      @include screen('mobile') {
        width: vw(11);
      }
    }
  }

  .scrolled-content {
    width: vw_d(830);
    margin-right: vw_d(521);
    margin-top: vw_d(35);
    padding-top: vw_d(10);
    overflow-y: auto;

    @include screen('mobile') {
      width: 100%;
      margin: vw(204) 0 0;
      padding-top: vw(10);
    }

    .memory {
      font-size: vw_d(22);
      line-height: 1.22;
      margin-bottom: vw_d(57);
      border-right: vw_d(4) solid #dd1a1a;
      padding-right: vw_d(18);

      @include screen('mobile') {
        font-size: vw(22);
        margin-bottom: vw(31);
        border-right: vw(4) solid #dd1a1a;
        padding-right: vw(8);
      }

      &:nth-child(odd) {
        border-color: #fff;
      }

      .author {
        font-family: $medium-font;
        font-size: vw_d(24);
        line-height: 1;
        color: #898989;
        margin-top: vw_d(-8);
        padding-bottom: vw_d(10);

        @include screen('mobile') {
          font-size: vw(24);
          margin-top: vw(-8);
          padding-bottom: vw(10);
        }
      }
    }
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
