@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding-top: vw_d(115);
  padding-bottom: vw_d(50);
  background-size: 100%;
  background-repeat: no-repeat;

  @include screen('mobile') {
    padding-top: vw(80);
    padding-bottom: vw(5);
  }

  .intro {
    .title {
      font-family: $bold-font;
      font-size: vw_d(97);
      line-height: 1.1;
      text-align: center;
      margin: 0;
      padding-bottom: vw_d(13);

      @include screen('mobile') {
        font-size: vw(42);
        line-height: 0.9;
        padding-bottom: 10px;
      }

      span {
        display: block;
      }
      .red {
        display: inline-block;
        line-height: 1;
        padding: 0 vw_d(22) 0;
        background-color: $red-color;

        @include screen('mobile') {
          line-height: 0.9;
          padding: 0 vw(5) 0;
        }
      }
    }

    .description {
      width: vw_d(1221);
      margin: 0 auto;
      font-size: vw_d(34);
      line-height: 1.1;
      text-align: center;
      padding-bottom: vw_d(210);

      @include screen('mobile') {
        width: 100%;
        max-width: vw(340);
        font-size: vw(19);
        line-height: 1.05;
        padding-bottom: vw(130);
      }

      b {
        display: block;
        font-family: $bold-font;
      }
    }

    .amount {
      font-size: vw_d(32);
      line-height: 1.1;
      text-align: center;
      padding-bottom: vw_d(10);

      @include screen('mobile') {
        font-size: vw(24);
        padding-bottom: vw(10);
      }
    }

    .additional-bold-text {
      font-size: vw_d(32);
      line-height: 1.1;
      text-align: center;
      padding-bottom: vw_d(40);
      font-family: $bold-font;

      @include screen('mobile') {
        font-size: vw(24);
        padding-bottom: vw(30);
      }
    }

    .info {
      font-family: $bold-font;
      font-size: vw_d(33);
      line-height: 1.2;
      text-align: center;

      @include screen('mobile') {
        font-size: vw(21);
        line-height: 1.1;
        padding: 0 vw(30);
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: vw_d(1302);
    padding-top: 10px;
    margin: 0 auto;

    @include screen('mobile') {
      width: 100%;
      max-width: vw(374);
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    width: vw_d(1332);
    padding: vw_d(30) 0 vw_d(12);

    @include screen('mobile') {
      width: 100%;
      max-width: vw(374);
      margin: 0 auto;
      padding: vw(24) vw(10) vw(30);
    }

    .item {
      width: vw_d(195);
      margin: 0 vw_d(13) vw_d(28) vw_d(14);
      transition: 0.5s;

      @include screen('mobile') {
        width: vw(102);
        margin: 0 vw(8) vw(15) vw(8);
      }

      @include screen('desktop') {
        &:hover {
          transform: scale(1.1);
        }
      }

      &.animation {
        background-position: -500px 0;
        background-image: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.1) 20%,
          rgba(255, 255, 255, 0.2) 40%,
          rgba(255, 255, 255, 0.1) 100%
        );
        background-repeat: no-repeat;
        background-size: 1000px 100%;
        animation: skeletonShine 0.5s linear 0s infinite normal forwards;

        .image-wrap,
        .info {
          opacity: 0;
        }
        .info {
          opacity: 0;
        }
      }

      .image-wrap {
        width: 100%;
        height: vw_d(250);
        overflow: hidden;

        @include screen('mobile') {
          height: vw(131);
        }

        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }

      .info {
        padding: vw_d(12) vw_d(5) vw_d(29) 0;

        @include screen('mobile') {
          padding: vw(12) 0 vw(8) 0;
        }
      }

      .name {
        margin: 0;
        font-family: $bold-font;
        font-size: vw_d(23);
        line-height: 1;
        padding-bottom: vw_d(5);

        @include screen('mobile') {
          font-size: vw(15);
          line-height: 1;
          padding-bottom: vw(2);
        }
      }

      .sub-info {
        font-size: vw_d(23);
        line-height: 1;

        @include screen('mobile') {
          font-size: vw(15);
        }
      }
    }
  }

  .pagination {
    display: flex;
    margin-left: vw_d(40);

    @include screen('mobile') {
      margin-left: 0; //vw(33);
    }

    .prevBtn,
    .nextBtn {
      padding: 0;
      font-family: $regular-font;
      font-size: vw_d(26);
      color: #fff;
      background-color: transparent;
      transition: 0.3s;

      @include screen('mobile') {
        font-size: vw(21);
      }

      @include screen('desktop') {
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      span {
        color: $red-color;
      }
    }

    .devider {
      display: inline-block;
      width: 1px;
      height: vw_d(44);
      background: #ffffff80;
      margin: 0 vw_d(28);

      @include screen('mobile') {
        height: vw(36);
        margin: 0 vw(17);
      }
    }
  }

  .scroll-top-btn {
    position: fixed;
    right: vw_d(128);
    bottom: vw_d(105);
    width: vw_d(89);
    height: vw_d(89);
    padding: 0;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    @include screen('mobile') {
      right: vw(6);
      bottom: vw(93);
      width: vw(48);
      height: vw(48);
    }

    svg {
      height: inherit;
    }
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
