@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.link {
  width: 100%;
  max-width: vw_d(242);
  height: vw_d(48);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $medium-font;
  font-size: vw_d(22);
  line-height: vw_d(28.95);
  background-color: #dd1a1a;
  transition: 0.3s;

  &:hover { 
    opacity: 0.8;
  }

  @include screen('mobile') {
    max-width: vw(338);
    height: vw(48);
    margin: 0 auto;
    font-size: vw(22);
    line-height: vw(28.95);
  }
}