
@font-face {
	font-family: 'NarkissBlock Regular';
	src: url('./fonts/NarkissBlock-Regular.woff2') format('woff2'),
		url('./fonts/NarkissBlock-Regular.woff') format('woff'),
		url('./fonts/NarkissBlock-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Medium';
	src: url('./fonts/NarkissBlock-Medium.woff2') format('woff2'),
		url('./fonts/NarkissBlock-Medium.woff') format('woff'),
		url('./fonts/NarkissBlock-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Bold';
	src: url('./fonts/NarkissBlock-Bold.woff2') format('woff2'),
		url('./fonts/NarkissBlock-Bold.woff') format('woff'),
		url('./fonts/NarkissBlock-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
