@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.search-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: vw_d(606);
  padding-bottom: vw_d(35);
  z-index: 1;
  @include screen('mobile') {
    max-width: vw(337);
    padding-bottom: vw(26);
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: vw_d(56);
    background-color: white;
    border-radius: vw_d(50);
    overflow: hidden;

    @include screen('mobile') {
      height: vw(47);
      border-radius: vw(50);
    }
  }

  .input-wrapper {
    position: relative;
    flex-grow: 1;
    input {
      display: block;
      width: 100%;
      height: vw_d(57);
      line-height: vw_d(40);
      padding: vw_d(2) vw_d(24) vw_d(5) vw_d(31);
      outline: none;
      border: none;
      font-family: $regular-font;
      font-size: vw_d(28);
      font-weight: 400;
      color: #212121;
      caret-color: #212121;
      position: relative;
      z-index: 3;
      background-color: transparent;

      @include screen('mobile') {
        height: vw(47);
        line-height: 1;
        padding: vw(3) vw(24) vw(5) vw(31);
        font-size: vw(20);
      }

      &::placeholder {
        color: #989898;
        font-family: $regular-font;
        font-weight: 400;
      }
    }

    .suggestion, 
    .searchString {
      position: absolute;
      top: vw_d(5);
      z-index: 1;
      display: block;
      width: 100%;
      height: vw_d(57);
      line-height: vw_d(40);
      padding: vw_d(2) vw_d(24) vw_d(5) vw_d(31);
      font-family: $regular-font;
      font-size: vw_d(28);
      font-weight: 400;
      color: #BAB2B2;
    }
    .searchString {
      width: auto;
      padding-left: 0;
      color: #212121;
      z-index: 2;
      // background-color: white;
    }
  }

  .search-btn {
    width: vw_d(58);
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    @include screen('mobile') {
      width: vw(40);
    }
  
    &:hover {
      cursor: pointer;
    }

    i {
      width: vw_d(36);

      @include screen('mobile') {
        width: vw(24);
      }
    }
  }

  .clear-btn {
    width: vw_d(20);
    height: 100%;
    margin: vw_d(17) 0 vw_d(20) vw_d(22);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    @include screen('mobile') {
      width: vw(15);
      margin: vw(19) 0 vw(20) vw(15);
    }
  }

  .suggestions {
    position: absolute;
    top: vw_d(27);
    width: 100%;
    height: vw_d(205);
    padding: vw_d(27) 0 vw_d(11);
    font-size: vw_d(24);
    font-weight: 300;
    color: #988e8e;
    background-color: #fff;
    border-bottom-left-radius: vw_d(30);
    border-bottom-right-radius: vw_d(30);
    overflow: hidden;

    @include screen('mobile') {
      top: vw(25);
      height: vw(175);
      padding: vw(27) 0 vw(11);
      font-size: vw(18);
      border-bottom-left-radius: vw(25);
      border-bottom-right-radius: vw(25);
    }

    &:after {
      content:'';
      position: absolute;
      top: vw_d(27);
      right: vw_d(24);
      width: vw_d(570);
      height: 1px;
      background-color: #dbd8d8;

      @include screen('mobile') {
        top: vw(24);
        right: vw(24);
        width: vw(290);
      }
    }
  }

  .suggestions-list {
    width: 100%;
    pointer-events: none;
    height: vw_d(168);
    padding: 0 vw_d(24);
    overflow-y: auto;

    @include screen('mobile') {
      height: vw(140);
      padding: 0 vw(24);
    }

    .item {
      pointer-events: all;
      cursor: pointer;
      padding: vw_d(10) 0;
      transition: 0.3s;

      @include screen('mobile') {
        padding: vw(10) 0;
      }

      &:hover {
        color: #212121;
      }
    }
  }
}