@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.sort-dropdown {
  position: relative;
  width: vw_d(261);
  padding: vw_d(19) vw_d(13) vw_d(25);

  @include screen('mobile') {
    width: vw(261);
    margin: 0 auto;
    padding: vw(20) 0 vw(40);
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    font-size: vw_d(20);
    width: vw_d(261);
    padding: vw_d(7) vw_d(10) vw_d(7) vw_d(11);
    background-color: rgba(0, 0, 0, 0.92);
    border: 1px solid #fff;

    @include screen('mobile') {
      font-size: vw(18);
      width: vw(261);
      padding: 4px 10px 4px 11px;
    }
  }

  .selected {
    position: relative;
    cursor: pointer;

    .chevron-icon {
      position: absolute;
      top: vw_d(6);
      left: 0;

      @include screen('mobile') {
        top: vw(6);
      }

      svg {
        width: vw_d(16);
        height: vw_d(16);

        @include screen('mobile') {
          width: vw(16);
          height: vw(16);
        }
      }
    } 
  }

  .list {
    height: 0;
    opacity: 0;
    pointer-events: none;
		transition: all 0.3s;

    &.is-open {
      height: vw_d(108);
      padding-top: vw_d(10);
      pointer-events: all;
      opacity: 1;

      @include screen('mobile') {
        padding-top: vw(7);
        height: vw(88);
      }
    }

    .item {
      padding: vw_d(10) 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      color: #fff;
      transition: 0.3s;

      @include screen('mobile'){
        padding: vw(7) 0;
      }

      @include screen('desktop') {
        &:hover {
          cursor: pointer;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}