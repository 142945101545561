@import 'src/styles/theme.scss';

/* stylelint-disable */
:export {
  desktop: #{$media-desktop};
  mobile: #{$media-mobile-max};
  small_mobile: #{$media-mobile-small};
  ultra_small_mobile: #{$media-mobile-ultra}
}
/* stylelint-enable */
