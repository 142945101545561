@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: vw_d(87);
  padding: vw_d(20) vw_d(61) vw_d(5) vw_d(62);
  background-color: #000;
  box-shadow: 0 2px 10px #000;
  transition: 0.3s;
  z-index: 1;

  @include screen('mobile') {
    height: vw(55);
    padding: vw(7) vw(16) 0 vw(16);
  }

  .actions {
    @include screen('mobile') {
      width: vw(245);
    }
  }

  .actions,
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen('mobile') {
      flex-direction: row-reverse;
    }

    .ynet-link {
      width: vw_d(130);

      @include screen('mobile') {
        width: vw(78);
      }
    }

    .site-logo {
      font-family: $bold-font;
      font-size: vw_d(37.82);
      line-height: 0.9;
      padding-right: vw_d(25);
      margin-top: vw_d(-13);

      span {
        display: inline-block;
      }

      .red {
        padding: 0 vw_d(13) 0;
        margin-left: vw_d(13);
        background-color: $red-color;
      }
    }
  }

  .withBackBtn {
    @include screen('mobile') {
      flex-direction: row;
    }
    
    .socials {
      @include screen('mobile') {
        width: vw(87);
        margin-right: 0;
        order: 3;
      }
    }
    .emai-link {
      @include screen('mobile') {
        width: vw(36);
        order: 2
  
      }
      i {
        @include screen('mobile') {
          width: 100%;
          margin-left: vw(5);
        }
      }
    }
  }

  .back-button {
    width: vw_d(160);
    height: vw_d(35);
    margin-top: vw_d(-9);
    font-family: $bold-font;
    font-size: vw_d(22);
    color: $red-color;
    background-color: #fff;
    border-radius: vw_d(5);
    text-align: center;
    white-space: nowrap;
    padding: 0;
    transition: 0.3s;

    @include screen('mobile') {
      width: vw(101);
      height: vw(25);
      margin-top: vw(-9);
      font-size: vw(14);
      border-radius: vw(5);
      order: 1;
    }

    @include screen('desktop') {
      &:hover {
        background-color: $red-color;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: vw_d(332);
    margin-left: vw_d(51);
    margin-right: vw_d(56);
    padding-bottom: vw_d(5);

    @include screen('mobile') {
      width: vw(95);
      margin-left: 0;
      padding-bottom: vw(5);
    }

    .text {
      font-family: $bold-font;
      font-size: vw_d(24);
      line-height: 1;
      padding-bottom: vw_d(6);

      @include screen('mobile') {
        display: none;
      }
    }

    .share-button {
      width: vw_d(33.45);
      padding: 0;
      background-color: transparent;
      transition: 0.3s;

      @include screen('mobile') {
        width: vw(23);
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .emai-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: vw_d(178);
    font-family: $medium-font;
    font-size: vw_d(17);
    line-height: 1.2;
    margin-left: vw_d(34);
    padding-bottom: vw_d(3);

    @include screen('mobile') {
      width: vw(160);
      font-size: vw(15);
      line-height: 0.9;
      margin-left: 0;
      padding-bottom: vw(8);
    }

    i {
      width: vw_d(58);
      margin-left: vw_d(15);

      @include screen('mobile') {
        width: vw(60);
        margin-left: vw(8);
      }
    }
  }
}