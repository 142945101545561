@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: #000;
  z-index: 1;

  .form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: vw_d(1192);
    padding: vw_d(65) vw_d(80) vw_d(51) vw_d(87);
    border-radius: vw_d(22);
    background-color: #d6d6d6;
    transform: translate(-50%, -50%);

    @include screen('mobile') {
      top: vw(17);
      left: vw(17);
      width: calc(100% - #{vw(36)});
      height: calc(100% - #{vw(36)});
      border-radius: vw(22);
      padding: vw(46) vw(17) vw(21);
      transform: none;
    }

    .form-content {
      @include screen('mobile') {
        overflow-y: auto;
        height: 100%;
      }
    }

    .title {
      font-family: $regular-font;
      font-weight: 400;
      font-size: vw_d(46);
      margin: 0 auto vw_d(15);

      @include screen('mobile') {
        font-size: vw(28);
        line-height: vw(30);
        margin: 0 auto vw(12);
      }
    }

    textarea,
    input {
      outline: 0;
      border: 1px solid #b4b4b4;
      border-radius: 1px;
      font-family: $regular-font;
      font-weight: 300;
      font-size: vw_d(26);
      width: 100%;
      padding: vw_d(11) vw_d(18) 1px;

      @include screen('mobile') {
        font-size: vw(20);
        padding: vw(7) vw(9) 0px;
      }

      &.error {
        border: 1px solid $red-color;
      }
    }

    textarea {
      height: vw_d(455);

      @include screen('mobile') {
        height: auto;
      }
    }

    .form-row {
      display: flex;
      justify-content: space-between;
      margin: vw_d(22) 0 vw_d(23);

      @include screen('mobile') {
        flex-direction: column;
        margin: vw(6) 0 vw_d(23);
      }
      
      input {
        max-width: vw_d(373);
        height: vw_d(52);
        padding: vw_d(10) vw_d(18) vw_d(11);

        @include screen('mobile') {
          max-width: 100%;
          height: vw(50);
          padding: vw(11) vw(12) vw(10);
        }
      }

      .save-button {
        width: vw_d(174);
        height: vw_d(52);
        text-align: center;
        font-family: $bold-font;
        font-size: vw_d(33);
        color: #fff;
        background-color: #dd1a1a;
        transition: 0.3s;

        @include screen('mobile') {
          width: 100%;
          height: vw(46);
          margin-top: vw(15);
          font-size: vw(24);
        };

        @include screen('desktop'){
          &:hover {
            opacity: 0.9;
            cursor: pointer;
          }
        }
      }
    }

    .terms {
      font-family: $regular-font;
      font-size: vw_d(17);
      font-weight: 400;
      text-align: left;

      @include screen('mobile') {
        width: 100%;
        font-size: vw(17);
        text-align: center;
      }

      a {
        text-decoration: underline;
      }
    }

    .success-screen {
      font-family: $medium-font;
      font-size: vw_d(46);
      text-align: center;
      padding-top: vw_d(222);
      padding-right: vw_d(9);

      @include screen('mobile') {
        font-size: vw(36);
        padding: 0 vw(40) 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      p {
        line-height: 1;
        @include screen('mobile') {
          line-height: 0.9;
        }
      }

      .sub-text {
        font-size: vw_d(36);

        @include screen('mobile') {
          font-size: vw(26);
          padding-top: vw(5);
          line-height: 1;
        }
      }

      svg {
        width: vw_d(46);

        @include screen('mobile') {
          width: vw(46);
        }
      }
    }
  }

  .close-button {
    position: absolute;
    top: vw_d(24);
    left: vw_d(22);
    background-color: transparent;
    transition: 0.3s;

    @include screen('mobile') {
      top: vw(14);
      left: vw(14);
    }

    @include screen('desktop'){
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    svg {
      width: vw_d(25);

      @include screen('mobile') {
        width: vw(16);

        path {
          fill: #414141;
        }
      }
    }
  }
}