@import 'src/styles/theme.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/mixins.scss';

.footer {
  padding: vw_d(15) 0;

  @include screen('mobile') {
    padding: vw(48) 0 100px;
  }

  .terms-and-policy {
    display: flex;
    justify-content: center;

    @include screen('mobile') { 
      padding-top: vw(40);
    }
  }

  .link {
    font-size: vw_d(16);
    line-height: 1;
    text-decoration: underline;

    @include screen('mobile') {
      font-size: vw(16);
    }
  } 

  .devider {
    width: 1px;
    height: vw_d(19);
    background-color: #fff;
    margin: 0 vw_d(5);

    @include screen('mobile') {
      height: vw(17);
      margin: 0 vw(5);
    }
  }
}