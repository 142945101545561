@import './theme.scss';

html {
  font-size: 62.5%;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  &.full-height {
    height: 100%;
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
  color: unset;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}
