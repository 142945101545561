$regular-font: 'NarkissBlock Regular', sans-serif;
$medium-font: 'NarkissBlock Medium', sans-serif;
$bold-font: 'NarkissBlock Bold', sans-serif;

//colors

$red-color: #de1a1a;

//screen resolutions
$media-mobile-small-ultra: 370;
$media-mobile-small-max: 450;
$media-max-mobile: 767;
$media-tablet-max: 1153;
$media-desktop-min: $media-max-mobile + 1;
$media-desktop-small-max: 1531;
$media-desktop-medium: 1690;

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-medium-desktop: '(max-width: #{$media-desktop-medium}px)';
$media-small-desktop: '(max-width: #{$media-desktop-small-max}px)';
$media-tablet: '(max-width: #{$media-tablet-max}px)';
$media-mobile-max: '(max-width: #{$media-max-mobile}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-ultra: '(max-width: #{$media-mobile-small-ultra}px)';

$media-non-mobile: '(min-width: #{$media-max-mobile}px)';
